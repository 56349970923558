import CustomerContactList from "./list";
import CustomerContactShow from "./show";


if (document.body.getAttribute("data-current-path") === "customer/contacts") {
    const action = document.body.getAttribute("data-current-action")
    if (action === "index") {
        new CustomerContactList();
    } else if(action === "show") {
        new CustomerContactShow();
    }
}